import React from 'react';

class NotFoundPage extends React.Component {
  componentDidMount() {
    window.location = '/';
  }

  render() {
    return null;
  }
}

export default NotFoundPage;
